<template lang='pug'>
h1 Настройки
settingsForm
</template>

<script>
import settingsForm from '@/components/settings-form'
export default {
	name: 'Settings',
	components: {settingsForm}
}
</script>