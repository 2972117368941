<template lang='pug'>
form
	.form-item
		.form-item__label Пол
		select.form-input(@change="selectGender",v-model="currentGender")
			option(value="0") Не выбран
			option(v-for="(g,index) in genders",:key="index",:value='index') {{g}}
	.form-item
		.form-item__label Лига
		select.form-input(v-model="currentLeague",:disabled="currentGender==0")
			option(value="0") Не выбрана
			option(:value='index',v-for="(g,index) in leagues",:key='index') {{g}}
	.form-item
		.form-item__label Год
		select.form-input(v-model="currentYear",:disabled="currentLeague==0")
			option(value="0") Не выбран
			option(:value='g',v-for="(g,index) in years",:key='index') {{g}}
	.form-item
		.form-item__label Стадия
		select.form-input(v-model="currentStage",:disabled="currentLeague==0 || loading")
			option(v-for="(g,index) in stages",:key='g.id',:value='g.id') {{g.name}}
	button(@click="goHome") Перейти к турнирной таблице
	button(@click="clearAll") Сбросить все настройки
</template>

<script>
export default {
	name: 'form-settings',
	computed: {
		loading() {return this.$store.getters.loading},

		genders() {return this.$store.getters.listGengers||{}},
		leagues() {
			const l=this.$store.getters.listLeagues;
			if(!(this.currentGender in l)){
				return {}
			}
			return l[this.currentGender]
		},
		years() {
			if(Object.keys(this.leagues).length === 0){
				return {}
			}
			const y=this.$store.getters.listYears

			if(!(this.currentGender in y)){return {}}
			if(!(this.currentLeague in y[this.currentGender])){return {}}

			return Object.keys(y[this.currentGender][this.currentLeague]).reverse() || [] 
		},
		stages(){
			return this.$store.getters.stages ||[]
		}
	},

	data(){
		return {
			currentGender:localStorage.getItem('gender') || 0,
			currentLeague:localStorage.getItem('league') || 0,
			currentYear:localStorage.getItem('year') || 0,
			currentStage:localStorage.getItem('stage') || 0,
		}
	},
	mounted() {
		this.$store.dispatch('getResTable')
	},
	watch: {
		currentGender: function (n) {
			if(n>0){
				this.currentLeague=0;
				this.currentYear=0;
			}
		},
		currentLeague: function (n) {
			if(n>0){
				this.currentYear=0;
			}
		},
		currentYear: function (n) {
			if(n>0){
				if(this.currentGender>0 && this.currentLeague>0){
					localStorage.setItem('gender',this.currentGender);
					localStorage.setItem('league',this.currentLeague);
					localStorage.setItem('year',this.currentYear);
					this.$store.dispatch('doApi')
				}
			}
		},
		currentStage: function (n) {
			localStorage.setItem('stage',n)
			this.$store.commit('setStage',n)
		}
	},
	methods:{
		clearAll(){
			this.currentGender=0;
			this.currentLeague=0;
			this.currentYear=0;
			this.currentStage=0;

			localStorage.setItem('gender',0);
			localStorage.setItem('league',0);
			localStorage.setItem('year',0);
			localStorage.setItem('stage',0);
		},
		goHome(){
			this.$router.push({name:'home'})
		}
	}
}
</script>

<style lang="scss">
.form-item{
	margin-bottom: 20px;
}

.form-item__label{
	display: block;
	margin-bottom: 10px;
}

input,select{
	position: static;
	height:40px;
	padding:0 10px;
	border:1px solid #ccc;
	background-color: #fff;
	width:100%;
	color:#333;
	outline:none;
	&:focus{
		border-color: #164e63;
	}
	&:disabled{
		position: static;
		z-index: 1;
	}
}

button{
	display: block;
	width:100%;
	background: #164e63;
	color:#fff;
	height: 48px;
	top: 0;
	padding: 10px 20px;
	margin-bottom: 20px;
}
</style>